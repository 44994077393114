import React from 'react';

export default function Footer() {
  return (
    <footer className='footer'>
      <span>
        <span className='font-weight-semibold'>Githubit</span>
        <span>
          {' '}
          support team <a href='tel:+12023401032'>+1 202-340-1032</a>
        </span>
      </span>
    </footer>
  );
}
