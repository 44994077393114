import React, {useContext, useEffect, useState} from 'react';
import '../../assets/scss/components/product-categories.scss';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {Button, Table, Image, Card, Space, Switch, Tooltip} from 'antd';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {export_url, IMG_URL} from '../../configs/app-global';
import {Excel} from 'antd-table-saveas-excel';
import {Context} from '../../context/context';
import CustomModal from '../../components/modal';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {addMenu, disableRefetch, setMenuData} from '../../redux/slices/menu';
import productService from '../../services/product';
import {fetchProducts} from '../../redux/slices/product';
import useDidUpdate from '../../helpers/useDidUpdate';
import {DebounceSelect} from '../../components/search';
import brandService from '../../services/brand';
import categoryService from '../../services/category';
import shopService from '../../services/shop';
import SearchInput from '../../components/search-input';
import formatSortType from '../../helpers/formatSortType';
import {useTranslation} from 'react-i18next';
import DeleteButton from '../../components/delete-button';

const ProductCategories = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const goToEdit = (row) => {
        dispatch(
            addMenu({
                id: `product-edit`,
                url: `product/${row.uuid}`,
                name: t('edit.product'),
            })
        );
        navigate(`/product/${row.uuid}`);
    };

    const columns = [
        {
            title: t('id'),
            dataIndex: 'id',
            sorter: true,
        },
        {
            title: t('image'),
            dataIndex: 'img',
            render: (img) => {
                return (
                    <Image
                        width={100}
                        src={IMG_URL + img}
                        placeholder
                        style={{borderRadius: 4}}
                    />
                );
            },
        },
        {
            title: t('name'),
            dataIndex: 'name',
        },
        {
            title: t('category'),
            dataIndex: 'category_name',
        },
        {
            title: t('active'),
            dataIndex: 'active',
            render: (active, row) => {
                return (
                    <Switch
                        onChange={() => {
                            setIsModalVisible(true);
                            setUUID(row.uuid);
                            setIsDelete(false);
                        }}
                        checked={active}
                    />
                );
            },
        },
        {
            title: t('options'),
            dataIndex: 'options',
            render: (data, row) => {
                return (
                    <Space>
                        <Button
                            type='primary'
                            icon={<EditOutlined/>}
                            onClick={() => goToEdit(row)}
                        />
                        <DeleteButton
                            icon={<DeleteOutlined/>}
                            onClick={() => {
                                setIsModalVisible(true);
                                setUUID(row.uuid);
                                setIsDelete(true);
                            }}
                        />
                    </Space>
                );
            },
        },
    ];
    const [uuid, setUUID] = useState(false);
    const {setIsModalVisible} = useContext(Context);
    const [isDelete, setIsDelete] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const {activeMenu} = useSelector((state) => state.menu, shallowEqual);
    const {products, meta, loading, params} = useSelector(
        (state) => state.product,
        shallowEqual
    );

    const goToImport = () => {
        dispatch(
            addMenu({
                id: 'product-import',
                url: `catalog/product/import`,
                name: t('product.import'),
            })
        );
        navigate(`/catalog/product/import`);
    };

    const productDelete = () => {
        setLoadingBtn(true);
        productService
            .delete(uuid)
            .then(() => {
                setIsModalVisible(false);
                toast.success(t('successfully.deleted'));
                dispatch(fetchProducts(params));
            })
            .finally(() => setLoadingBtn(false));
    };

    const handleActive = () => {
        setLoadingBtn(true);
        productService
            .setActive(uuid)
            .then(() => {
                setIsModalVisible(false);
                dispatch(fetchProducts(params));
                toast.success(t('successfully.updated'));
            })
            .finally(() => setLoadingBtn(false));
    };

    function onChangePagination(pagination, filters, sorter) {
        const {pageSize: perPage, current: page} = pagination;
        const {field: column, order} = sorter;
        const sort = formatSortType(order);
        dispatch(
            setMenuData({activeMenu, data: {perPage, page, column, sort}})
        );
    }

    useDidUpdate(() => {
        const data = activeMenu.data;
        const paramsData = {
            search: data?.search,
            brand_id: data?.brand?.value,
            category_id: data?.category?.value,
            shop_id: data?.shop?.value,
            sort: data?.sort,
            column: data?.column,
            perPage: data?.perPage,
            page: data?.page,
        };
        dispatch(fetchProducts(paramsData));
    }, [activeMenu.data]);

    useEffect(() => {
        if (activeMenu.refetch) {
            dispatch(fetchProducts());
            dispatch(disableRefetch(activeMenu));
        }
    }, [activeMenu.refetch]);


    const excelExport = () => {
        setDownloading(true);
        const body = {
            shop_id: activeMenu.data.shop.value
        }
        productService
            .export(body)
            .then((res) => {
                const body = export_url + res.data.file_name;
                window.location.href = body;
            })
            .finally(() => setDownloading(false));
    };

    const goToAddProduct = () => {
        dispatch(
            addMenu({
                id: 'product-add',
                url: 'product/add',
                name: t('add.product'),
            })
        );
        navigate('/product/add');
    };

    async function fetchBrands(search) {
        return brandService.search(search).then(({data}) =>
            data.map((item) => ({
                label: item.title,
                value: item.id,
            }))
        );
    }

    async function fetchCategories(search) {
        const params = {search};
        return categoryService.search(params).then(({data}) =>
            data.map((item) => ({
                label: item.translation?.title,
                value: item.id,
            }))
        );
    }

    async function fetchShops(search) {
        const params = {search};
        return shopService.search(params).then(({data}) =>
            data.map((item) => ({
                label: item.translation?.title,
                value: item.id,
            }))
        );
    }

    const handleFilter = (item, name) => {
        dispatch(
            setMenuData({
                activeMenu,
                data: {...activeMenu.data, [name]: item},
            })
        );
    };

    console.log("activeMenu", activeMenu?.data?.shop)

    return (
        <Card
            title={t('products')}
            extra={
                <Space>
                    <DebounceSelect
                        placeholder={t('select.shop')}
                        fetchOptions={fetchShops}
                        style={{minWidth: 150}}
                        onChange={(shop) => handleFilter(shop, 'shop')}
                        value={activeMenu.data?.shop}
                    />
                    <DebounceSelect
                        placeholder={t('select.category')}
                        fetchOptions={fetchCategories}
                        style={{minWidth: 150}}
                        onChange={(category) => handleFilter(category, 'category')}
                        value={activeMenu.data?.category}
                    />
                    <DebounceSelect
                        placeholder={t('select.brand')}
                        fetchOptions={fetchBrands}
                        style={{minWidth: 150}}
                        onChange={(brand) => handleFilter(brand, 'brand')}
                        value={activeMenu.data?.brand}
                    />
                    <Button type='primary' onClick={goToAddProduct}>
                        {t('add.product')}
                    </Button>
                    <Tooltip title={t("select.shop")}>
                    <Button onClick={excelExport} loading={downloading} disabled={!activeMenu?.data?.shop} >
                        {t('export')}
                    </Button>
                    </Tooltip>
                    <Button onClick={goToImport}>{t('import')}</Button>
                </Space>
            }
        >
            <div className='d-flex justify-content-end'>
                <SearchInput
                    placeholder={t('search')}
                    handleChange={(search) => handleFilter(search, 'search')}
                />
            </div>
            <Table
                rowSelection
                loading={loading}
                columns={columns}
                dataSource={products}
                pagination={{
                    pageSize: params.perPage,
                    page: params.page,
                    total: meta.total,
                    defaultCurrent: params.page,
                }}
                onChange={onChangePagination}
                rowKey={(record) => record.id}
            />
            <CustomModal
                click={isDelete ? productDelete : handleActive}
                text={isDelete ? t('delete.product') : t('set.active.product')}
                loading={loadingBtn}
            />
        </Card>
    );
};

export default ProductCategories;
