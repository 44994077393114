import request from './request';

const pageService = {
  getAll: (params) => request.get('dashboard/admin/page/paginate', { params }),
  getById: (id) => request.get(`dashboard/admin/page/${id}`),
  create: (data) => request.post('dashboard/admin/page', data),
  update: (id, data) => request.put(`dashboard/admin/page/${id}`, data),
  delete: (id) => request.delete(`dashboard/admin/page`, { data: id }),
  setActive: (id) => request.post(`dashboard/admin/page/${id}/active/status`),
  publish: (id) => request.post(`dashboard/admin/page/${id}/publish`),
};

export default pageService;
