import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, Col, Form, Input, Row, Space, Spin, Switch } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  disableRefetch,
  removeFromMenu,
  setMenuData,
} from '../../redux/slices/menu';
import ImageUploadSingle from '../../components/image-upload-single';
import { fetchBlogs } from '../../redux/slices/blog';
import LanguageList from '../../components/language-list';
import getTranslationFields from '../../helpers/getTranslationFields';
import createImage from '../../helpers/createImage';
import { useTranslation } from 'react-i18next';
import CkeEditorEdit from '../../components/ckeEditorEdit';
import pageService from '../../services/page';
import CkeEditor1 from '../../components/ckeEditor1';

export default function PageEdit() {
  const { t } = useTranslation();
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const { languages, defaultLang } = useSelector(
    (state) => state.formLang,
    shallowEqual
  );
  const [page,setPage]=useState({title_ar:'',title_en:"",content_ar:"",content_en:"",active:true})
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();

  const [image, setImage] = useState(activeMenu.data?.image || null);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      const data = form.getFieldsValue(true);
      dispatch(setMenuData({ activeMenu, data }));
    };
  }, []);

  const onFinish = (values) => {
  
    console.log('body => ', page);
    setLoadingBtn(true);
    const nextUrl = 'pages';
    pageService
      .update(id, page)
      .then(() => {
        toast.success(t('successfully.created'));
        dispatch(removeFromMenu({ ...activeMenu, nextUrl }));
        navigate(`/${nextUrl}`);
        dispatch(fetchBlogs({}));
      })
      .finally(() => setLoadingBtn(false));
  };

  function getLanguageFields(data) {
    if (!data) {
      return {};
    }
    const { translations } = data;
    const result = languages.map((item) => ({
      [`title[${item.locale}]`]: translations.find(
        (el) => el.locale === item.locale
      )?.title,
      [`description[${item.locale}]`]: translations.find(
        (el) => el.locale === item.locale
      )?.description,
      [`short_desc[${item.locale}]`]: translations.find(
        (el) => el.locale === item.locale
      )?.short_desc,
    }));
    return Object.assign({}, ...result);
  }

  const fetchBlog = (id) => {
    setLoading(true);
    pageService
      .getById(id)
      .then((res) => {
        setPage(res.data)
        console.log(page)
        //let blog = res.data;
       
      })
      .finally(() => {
        setLoading(false);
        dispatch(disableRefetch(activeMenu));
      });
  };

  useEffect(() => {
    fetchBlog(id);
    
  }, [activeMenu.refetch]);

  return (
    <Card title={t('edit.page')} >
      {!loading ? (
        <Form
          name='page-edit'
          layout='vertical'
          onFinish={onFinish}
          initialValues={{
            active: page.active==1,
            ...page
          }}
        >
          <Row gutter={12}>
            <Col span={9}>
             
                <Form.Item
                  key={'title_ar'}
                  label={t('Arabic title')}
                  name={`title_ar`}
                  value={page.title_ar}
                  rules={[
                    {
                      required: true,
                      message: t('requried'),
                    },
                  ]}
                 
                >
                  <Input value={page.title_ar} onChange={(e)=>setPage({...page,title_ar:e.target.value})} />
                </Form.Item>
           
            </Col>
            <Col span={9}>
             
             <Form.Item
               key={'title_en'}
               label={t('English title')}
               name={`title_en`}
               value={page.title_en}
               rules={[
                 {
                   required: true,
                   message: t('requried'),
                 },
               ]}
              
             >
               <Input value={page.title_en} onChange={(e)=>setPage({...page,title_en:e.target.value})}t />
             </Form.Item>
         
         </Col>
 
            <Col>
              <div className='col-md-12 col-sm-6'>
                <Form.Item
                  label={t('active')}
                  name='active'
                  valuePropName='checked'
                >
                  <Switch checked={page.active} onClick={()=>{setPage({...page,active:!page.active})}} />
                </Form.Item>
              </div>
            </Col>
          
            <Col span={24}>
            <Form.Item
                 
                  name='content_ar'
                 key='content_ar'
                >
            <CkeEditor1 key={'content_ar'} label={"Arabic content"} value={page.content_ar} setter={(data)=>setPage({...page,content_ar:data})}  />
          </Form.Item>
          </Col>
          <Col span={24}>
            <CkeEditor1 key={'content_en'} label={"English content"} value={page.content_en} setter={(data)=>setPage({...page,content_en:data})}  />
          </Col>
          </Row>
          <Space>
            <Button type='primary' htmlType='submit' loading={loadingBtn}>
              {t('save')}
            </Button>
          </Space>
        </Form>
      ) : (
        <div className='d-flex justify-content-center align-items-center'>
          <Spin size='large' className='py-5' />
        </div>
      )}
    </Card>
  );
}
