import React, { useContext, useEffect, useState } from 'react';
import {
  CloudUploadOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Button, Card, Image, Space, Switch, Table, Tooltip } from 'antd';
import { toast } from 'react-toastify';
import CustomModal from '../../components/modal';
import { Context } from '../../context/context';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addMenu, disableRefetch, setMenuData } from '../../redux/slices/menu';
import { fetchBlogs } from '../../redux/slices/blog';
import useDidUpdate from '../../helpers/useDidUpdate';
import formatSortType from '../../helpers/formatSortType';
import pageService from '../../services/page';
import getImage from '../../helpers/getImage';
import { useTranslation } from 'react-i18next';
import DeleteButton from '../../components/delete-button';
import { fetchPages } from '../../redux/slices/page';

export default function Pages() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { languages, defaultLang } = useSelector(
    (state) => state.formLang,
    shallowEqual
  );

  const [columns, setColumns] = useState([
    {
      title: t('page'),
      is_show: true,
      dataIndex: 'translation',
      key: 'translation',
      render: (active, row)  =>{
       return row.title_en
      } ,
    },
    {
      title: t('active'),
      is_show: true,
      dataIndex: 'active',
      key: 'active',
      render: (active, row) => (
        <Switch
          checked={active}
          onChange={() => {
            setId(row.id);
            setIsDelete(false);
            setIsPublish(false);
            setIsModalVisible(true);
          }}
        />
      ),
    },
    {
      title: t('options'),
      is_show: true,
      key: 'options',
      dataIndex: 'options',
      render: (data, row) => {
        return (
          <Space>
           
            <Button
              type='primary'
              icon={<EditOutlined />}
              onClick={() => goToEdit(row)}
            />
            {/* <DeleteButton
              icon={<DeleteOutlined />}
              onClick={() => {
                setSelectedRows([row.id]);
                setIsDelete(true);
                setIsPublish(false);
                setIsModalVisible(true);
              }}
            /> */}
          </Space>
        );
      },
    },
  ]);
  const goToEdit = (row) => {
    dispatch(
      addMenu({
        url: `page/edit/${row.id}`,
        id: 'page_edit',
        name: t('edit.page'),
      })
    );
    navigate(`/page/edit/${row.id}`);
  };
  const goToImport = (row) => {
    dispatch(
      addMenu({
        url: `blog/import`,
        id: 'blog_import',
        name: t('import.blog'),
      })
    );
    navigate(`/page/import`);
  };
  const goToAddBlog = () => {
    dispatch(
      addMenu({
        id: 'page/add',
        url: 'page/add',
        name: t('add.page'),
      })
    );
    navigate('/page/add');
  };
  const { setIsModalVisible } = useContext(Context);
  const [id, setId] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [isPublish, setIsPublish] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  // const { pages, meta, loading, params } = useSelector(
  //   (state) => state.pages,
  //   shallowEqual
  // );
  const   meta=[], loading=()=>{}, params={} ;
  const [pages,setPages]=useState([])
  const blogDelete = () => {
    setLoadingBtn(true);
    pageService
      .delete({ ids: selectedRows })
      .then(() => {
        toast.success(t('successfully.deleted'));
        dispatch(fetchPages());
        setIsModalVisible(false);
      })
      .finally(() => setLoadingBtn(false));
  };

  const blogSetActive = () => {
    setLoadingBtn(true);
    pageService
      .setActive(id)
      .then(() => {
        toast.success(t('successfully.updated'));
        dispatch(fetchPages());
        setIsModalVisible(false);
      })
      .finally(() => setLoadingBtn(false));
  };

  const blogPublish = () => {
    setLoadingBtn(true);
    pageService
      .publish(id)
      .then(() => {
        toast.success(t('successfully.updated'));
        dispatch(fetchPages());
        setIsModalVisible(false);
      })
      .finally(() => setLoadingBtn(false));
  };

  useEffect(() => {
    pageService.getAll().then(res=>{
      //   console.log(res)
         setPages(res)
        })
    if (activeMenu.refetch) {
     
      dispatch(disableRefetch(activeMenu));
    }
  }, [activeMenu.refetch]);

  useDidUpdate(() => {
    const data = activeMenu.data;
    const paramsData = {
      sort: data?.sort,
      column: data?.column,
      perPage: data?.perPage,
      page: data?.page,
    };
    dispatch(fetchPages(paramsData));
  }, [activeMenu.data]);

  function onChangePagination(pagination, filters, sorter) {
    const { pageSize: perPage, current: page } = pagination;
    const { field: column, order } = sorter;
    const sort = formatSortType(order);
    dispatch(
      setMenuData({ activeMenu, data: { perPage, page, column, sort } })
    );
  }
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRowKeys);
    },
  };
  return (
    <Card
      title={t('Page')}
      extra={
        <Space>
          <Button
            type='primary'
            icon={<PlusCircleOutlined />}
            onClick={goToAddBlog}
          >
            {t('add.page')}
          </Button>
          {/* <Button
            type='primary'
            icon={<CloudUploadOutlined />}
            onClick={goToImport}
          >
            {t('import')}
          </Button> */}
          <DeleteButton
            type='danger'
            onClick={blogDelete}
            disabled={Boolean(!selectedRows.length)}
          >
            {t('delete.all')}
          </DeleteButton>
         
        </Space>
      }
    >
      <Table
        rowSelection={rowSelection}
        columns={columns?.filter((item) => item.is_show)}
        dataSource={pages}
        pagination={{
          pageSize: params.perPage,
          page: params.page,
          total: meta.total,
          defaultCurrent: params.page,
        }}
        rowKey={(record) => record.id}
        onChange={onChangePagination}
        loading={loading}
      />
      <CustomModal
        click={isPublish ? blogPublish : isDelete ? blogDelete : blogSetActive}
        text={
          isPublish
            ? t('publish.page')
            : isDelete
            ? t('delete.page')
            : t('set.active.page')
        }
        loading={loadingBtn}
      />
    </Card>
  );
}
