import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, Col, Form, Input, Row, Space, Switch } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { removeFromMenu, setMenuData } from '../../redux/slices/menu';
import ImageUploadSingle from '../../components/image-upload-single';
import { fetchBlogs } from '../../redux/slices/blog';
import pageService from '../../services/page';
import LanguageList from '../../components/language-list';
import { useTranslation } from 'react-i18next';
import CkeEditor from '../../components/ckeEditor';
import CkeEditor1 from '../../components/ckeEditor1';

export default function PageAdd() {
  const { t } = useTranslation();
  const { activeMenu } = useSelector((state) => state.menu, shallowEqual);
  const [page,setPage]=useState({title_ar:'',title_en:"",content_ar:"",content_en:"",active:true})
  const { languages, defaultLang } = useSelector(
    (state) => state.formLang,
    shallowEqual
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [image, setImage] = useState(activeMenu.data?.image || null);
  const [loadingBtn, setLoadingBtn] = useState(false);

  useEffect(() => {
    return () => {
      const data = form.getFieldsValue(true);
      dispatch(setMenuData({ activeMenu, data }));
    };
  }, []);

  function getTranslationFields(values, field = 'title') {
    const list = languages.map((item) => ({
      [item.locale]: values[`${field}[${item.locale}]`],
    }));
    return Object.assign({}, ...list);
  }

  const onFinish = (values) => {
    console.log(values.description);
  
    console.log('body => ', page);
    setLoadingBtn(true);
    const nextUrl = 'blogs';
    pageService
      .create(page)
      .then(() => {
        toast.success(t('successfully.created'));
        dispatch(removeFromMenu({ ...activeMenu, nextUrl }));
        navigate(`/${nextUrl}`);
        dispatch(fetchBlogs({}));
      })
      .finally(() => setLoadingBtn(false));
  };

  return (
    <Card title={t('add.page')} >
      <Form
        name='page-add'
        layout='vertical'
        onFinish={onFinish}
        form={form}
        initialValues={{
          active: true,
          ...activeMenu.data,
        }}
      >
        <Row gutter={12}>
          <Col span={6}>
           
              <Form.Item
                key={'title_ar'}
                label={t('Arabic title')}
                name={`title_ar`}
                rules={[
                  {
                    required:true,
                    message: t('required'),
                  },
                ]}
               
              >
                <Input name={'title_ar'} value={page.title_ar} onChange={(e)=>setPage({...page,title_ar:e.target.value})} />
              </Form.Item>
            
          </Col>
          <Col span={6}>
          
              <Form.Item
                key={'title_en'}
                label={t('English title')}
                name={`title_en`}
                rules={[
                  {
                    required:true,
                    message: t('required'),
                  },
                ]}
               
              >
                <Input name={'title_en'} value={page.title_en} onChange={(e)=>setPage({...page,title_en:e.target.value})} />
              </Form.Item>
          
          </Col>
  
          <Col>
            <div className='col-md-12 col-sm-6'>
              <Form.Item
                label={t('active')}
                name='active'
                valuePropName='checked'
              >
                <Switch checked={page.active} onClick={()=>setPage({...page,active:!page.active})}  />
              </Form.Item>
            </div>
          </Col>
         
          <Col span={24}>
            <CkeEditor1 label={"Arabic content"} setter={(data)=>setPage({...page,content_ar:data})}  />
          </Col>
          <Col span={24}>
            <CkeEditor1 label={"English content"} setter={(data)=>setPage({...page,content_en:data})}  />
          </Col>
        </Row>
        <Space>
          <Button type='primary' htmlType='submit' loading={loadingBtn}>
            {t('save')}
          </Button>
        </Space>
      </Form>
    </Card>
  );
}
